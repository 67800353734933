import React from "react";
import { Link } from "react-router-dom";
import CoverModal from "../components/CoverModal";
import { SunkenCityMobileStyles, SunkenCityStyles } from "./CoverStyles";
import styles from "./Home.module.css";
import SunkenCityCoverSmall from "../media/SunkenCityCoverSmall.png";
import SunkenCityCoverLarge from "../media/SunkenCityCoverLarge.png";
import SunkenCityCreatureSmall from "../media/SunkenCityCreatureSmall.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Home: React.FC = () =>
{
	const { width } = useWindowDimensions();

	return (
		<div className={styles.container}>
			<header className={styles.header}>
				<nav className={styles.topNav}>
					<a draggable="false" target="_blank" href="https://www.instagram.com/osaki_seiichi/" rel="noreferrer">instagram</a>
					<a draggable="false" target="_blank" href="https://www.youtube.com/channel/UCgregZ1IIlk0mpyYCTv1Tkw/featured" rel="noreferrer">youtube</a>
					<a draggable="false" target="_blank" href="https://osakiseiichi.bandcamp.com/" rel="noreferrer">bandcamp</a>
					<div className={styles.topNavRight}>
						<a
							draggable="false"
							target="_blank"
							href="https://distrokid.com/hyperfollow/osakiseiichi/the-tale-of-a-long-forgotten-sunken-city"
							rel="noreferrer">
							music
						</a>
					</div>
				</nav>
			</header>
			<div className={styles.mainContent}>
				<CoverModal
					style={width > 450 ? SunkenCityStyles : SunkenCityMobileStyles}
					image={SunkenCityCoverSmall}
					imageModal={SunkenCityCoverLarge}
					alt="The Tale Of A Long Forgotten Sunken City Cover" />
				<div className={styles.sunkenCityQuote}>
					<span>
						strange noises coming from the city
						<br />
						~ they were so frightening, yet so intriguing
					</span>
				</div>
				<Link
					className={styles.sunkenCityStory}
					draggable="false"
					to="sunken-city-story">
					<img
						className={styles.sunkenCityStoryImage}
						src={SunkenCityCreatureSmall}
						alt="The Tale Of A Long Forgotten Sunken City Lore"
						draggable="false" />
				</Link>
			</div>
			<footer className={styles.footer}>
				<h2 draggable="false">大﨑 誠一</h2>
				<h1 draggable="false">osaki seiichi</h1>
			</footer>
		</div>
	);
};

export default Home;
