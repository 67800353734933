import React from "react";
import CSS from "csstype";
import styles from "./SunkenCityStory.module.css";
import Pillar from "../../../media/Pillar1280.png";
import Alga from "../../../media/Alga512.png";
import Fish from "../../../media/Fish246.png";
import Back from "../../../components/Back";

const BackStyle: CSS.Properties = {
	minHeight: "3vh",
	height: "3vh",
	maxHeight: "3vh",
	width: "22px",
	minWidth: "22px",
	maxWidth: "22px",
	color: "#c2d1d6",
};

const SunkenCityStory: React.FC = () => (
	<div className={styles.container}>
		<Back style={BackStyle} />
		<div className={styles.storyContainer}>
			<p className={styles.track1}>
				There is an ancient story surrounding this part of the island.
				The tale goes something like this. Once upon a time, in the middle of a burning sunny day,
				a boy wandered into the sea, not knowing what lies ahead. He was stumbling and couldn’t
				walk straight, but somehow he knew where to continue. As the sunlight hit his eye,
				out of nowhere, a city appeared. He felt confused but wanted to explore
				this mysterious city. The ancient, sunlit alleyways made him dizzy and tired.
				He fell and hit his head.
			</p>
			<p className={styles.track2}>
				As soon as the boy woke up, sudden worry, anxiety and unease were upon him.
				He saw a silhouette but not a human one. He could hear eerie sounds in the distance
				and knew they were coming closer. Scared to the bone, he couldn’t even catch his breath
				before snake-like creatures pointed a trident at his throat. They took him.
				Surrounded, he couldn’t defend himself. They forced him to go with them,
				not knowing if he would survive.
			</p>
			<p className={styles.track3}>
				They approached a big gate. The Cathedral Of Secrets was in front of them.
				They let him in. The creatures talked for hours about the city and the story
				surrounding it. The boy realized he couldn’t go back home, and he had to stay
				and become one of them. As this realization came to him, he tried to break free.
				Rushing, he managed to leave the Cathedral Of Secrets, having creatures right
				behind his back. He dashed from the tridents, fighting for his life.
				He made several turns through the alleyways as creatures screamed at him.
				The boy managed to lose them. Thinking it was over, he felt relief. But then suddenly…
			</p>
			<div className={styles.albumTitleContainer}>
				<h1 className={styles.albumTitle}>水底に眠る 忘れられし街の物語</h1>
			</div>
			<img
				className={styles.alga}
				src={Alga}
				alt="Alga"
				draggable="false" />
			<img
				className={styles.pillar}
				src={Pillar}
				alt="Pillar of the ruins"
				draggable="false" />
			<img
				className={styles.fish1}
				src={Fish}
				alt="Fish"
				draggable="false" />
			<img
				className={styles.fish2}
				src={Fish}
				alt="Fish"
				draggable="false" />
		</div>
	</div>
);

export default SunkenCityStory;
