import React, { useState } from "react";
import CSS from "csstype";
import styles from "./CoverModal.module.css";

type Props = {
	image: string;
	imageModal: string;
	alt: string;
	style: CSS.Properties<string | number, string & {}>;
}

const CoverModal: React.FC<Props> = (props) =>
{
	const [open, setOpen] = useState<boolean>(false);

	return (
		<div
			className={styles.container}
			draggable="false">
			{
				open
					&& (
						<div
							className={styles.modalContainer}
							onClick={() => setOpen(false)}>
							<img
								className={styles.modalCover}
								src={props.imageModal}
								alt={props.alt}
								draggable="false"
								onClick={(e) => e.stopPropagation()} />
						</div>
					)
			}
			<img
				className={styles.clickableCover}
				style={props.style}
				src={props.image}
				alt={props.alt}
				draggable="false"
				onClick={() => setOpen(true)} />
		</div>
	);
};
export default CoverModal;
