import CSS from "csstype";

const SunkenCityStyles: CSS.Properties = {
	height: "100px",
	width: "100px",
	position: "absolute",
	top: "20%",
	left: "30%",
	backgroundColor: "#c2d1d6",
	boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
};

const SunkenCityMobileStyles: CSS.Properties = {
	height: "100px",
	width: "100px",
	position: "absolute",
	top: "20%",
	left: "20%",
	backgroundColor: "#c2d1d6",
	boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
};

export { SunkenCityStyles, SunkenCityMobileStyles };
