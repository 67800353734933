import React from "react";
import CSS from "csstype";
import { useNavigate } from "react-router-dom";
import styles from "./Back.module.css";

type Props = {
	style: CSS.Properties<string | number, string & {}>;
}

const Back: React.FC<Props> = (props: Props) =>
{
	const navigate = useNavigate();

	return (
		<div
			className={styles.container}
			onClick={() => navigate(-1)}
			style={props.style}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				stroke="currentColor"
				fill="currentColor"
				viewBox="0 0 512 512">
				<path d="M427 234.625H167.296l119.702-119.702L256 85 85 256l171 171 29.922-29.924-118.626-119.701H427v-42.75z" />
			</svg>
		</div>
	);
};

export default Back;
