import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import SunkenCityStory from "./pages/mainContent/sunkenCity/SunkenCityStory";
import Home from "./pages/Home";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<HashRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="sunken-city-story" element={<SunkenCityStory />} />
			</Routes>
		</HashRouter>
	</React.StrictMode>,
);
